import Image from "next/image";
import React from "react";
import Styles from "./hero.module.scss";
import arrow from "../../../public/images/landing/arrow.png";

function Hero() {
  return (
    <div
      className={`${Styles.landingHero} h-100 w-100 p-0 m-0`}
      style={{ backgroundImage: `url('/images/landing/hero.jpg')` }}
    >
      <div className="container-fluid h-100 w-100 p-0 m-0 d-flex flex-column">
        <div className="row hero-row flex-grow-1 w-100 p-0 m-0">
          <div className="col text-center align-self-center">
            <h2 className="display-1 text-white p-0 m-0">
              It&apos;s all about{" "}
              <em className={Styles.subtitleTextAccent}>your moment</em>
            </h2>
          </div>
        </div>
        <div className="row w-100 p-0 m-0">
          <div className="col text-center mb-5">
            <a href="#conocenos" id="arrow-ref">
              <Image src={arrow} alt="flecha" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
