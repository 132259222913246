import React from "react";
import Styles from "./about-us.module.scss";

function AboutUs() {
  return (
    <div
      id="conocenos"
      className={`${Styles.aboutUsContainer} container-fluid bg-primary`}
      style={{ backgroundImage: `url('/images/landing/icon.png')` }}
    >
      <div className="row py-3">
        <div className="col text-center text-white mt-5 mb-3">
          <h1 className="d-none">My Digital Moment</h1>
          <h2
            className={`${Styles.landingSectionTitle} landing-section-title text-white`}
          >
            Conoce My Digital Moment
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xl-4 offset-xl-4 text-center text-white mb-5">
          <p className="about-us-text">
            Somos la combinación perfecta entre la tecnología y el amor que te
            ayudará a digitalizar y volver únicos los momentos más importantes
            de tu vida a través de nuestras invitaciones inteligentes.
          </p>
          <br />
          <p className="about-us-text">
            Vamos mucho más allá de lo típico, acompañados siempre de la más
            alta calidad en desarrollo.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
