import Image from "next/image";
import React from "react";
import Styles from "./footer.module.scss";
import logo from "../../../public/images/Wordmark-MDM-footer.png";
import appstore from "../../../public/images/landing/appstore.svg";

function Footer() {
  return (
    <footer>
      <div className={`${Styles.footerDiv} container-fluid py-5 text-white`}>
        <div className={`${Styles.footerDiv} row`}>
          <div
            className={`${Styles.footerDiv} col-xl-8 offset-xl-2 d-flex align-items-center d-none d-lg-flex`}
          >
            <span className="ms-0 me-auto">
              Sigue nuestras redes sociales
              <a
                href="https://www.facebook.com/mydigitalmoment"
                target="_blank"
                rel="noopener noreferrer"
                className={`${Styles.footerA} px-2`}
              >
                <i className="fab fa-facebook-f fa-2x" />
              </a>
              <a
                href="https://www.instagram.com/my.digitalmoment"
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.footerA}
              >
                <i className="fab fa-instagram fa-2x" />
              </a>
            </span>
            <span className="me-auto ms-auto">
              <a
                href="mailto:hola@mydigitalmoment.com"
                className={Styles.footerA}
              >
                <i className="far fa-envelope fa-2x pe-2" />{" "}
                hola@mydigitalmoment.com
              </a>
            </span>
            <span className="ms-auto me-0">
              <a
                href="https://wa.me/528445881591?text=Hola,%0AMe%20podr%C3%ADan%20dar%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20invitaciones%20digitales%3F"
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.footerA}
              >
                <i className="fab fa-whatsapp fa-2x pe-2" /> +52 (844) 588 1591
              </a>
            </span>
          </div>
          <div className="d-block d-lg-none">
            <p className="ms-0 me-auto">
              <a
                href="https://www.facebook.com/mydigitalmoment"
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.footerA}
              >
                <i className="fab fa-facebook-f fa-2x pe-2" />
              </a>
              <a
                href="https://www.instagram.com/my.digitalmoment"
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.footerA}
              >
                <i className="fab fa-instagram fa-2x pe-2" />
              </a>
              Sigue nuestras redes
            </p>
            <p className="me-auto ms-auto">
              <a
                href="mailto:hola@mydigitalmoment.com"
                className={Styles.footerA}
              >
                <i className="far fa-envelope fa-2x pe-2" />{" "}
                hola@mydigitalmoment.com
              </a>
            </p>
            <p className="ms-auto me-0">
              <a
                href="https://wa.me/528445881591?text=Hola,%0AMe%20podr%C3%ADan%20dar%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20invitaciones%20digitales%3F"
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.footerA}
              >
                <i className="fab fa-whatsapp fa-2x pe-2" /> +52 (844) 588 1591
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <hr className={Styles.footerHr} />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 offset-xl-2 d-flex align-items-center d-none d-md-flex">
            <span className="ms-0 me-auto d-flex align-items-center">
              Made with <i className="fas fa-heart mx-1" /> by{" "}
              <Image src={logo} alt="logo" className="px-1" />
            </span>
            <span className="me-2">
              <a
                href="https://apps.apple.com/mx/app/my-digital-moment/id1541274546"
                target="blank"
              >
                <Image src={appstore} alt="Appstore" />
              </a>
            </span>
            <span className="me-0">
              <a
                href="https://play.google.com/store/apps/details?id=com.arc.my_digital_moment"
                target="blank"
              >
                <Image
                  alt="Disponible en Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png"
                  height={60}
                  width={155}
                />
              </a>
            </span>
          </div>
          <div className="d-block d-md-none">
            <p className="ms-0 me-auto d-flex align-items-center">
              Made with <i className="fas fa-heart mx-1" /> by{" "}
              <Image src={logo} alt="logo" className="px-1" />
            </p>
            <p className="me-2">
              <a
                href="https://apps.apple.com/mx/app/my-digital-moment/id1541274546"
                target="blank"
              >
                <Image src={appstore} alt="Appstore" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.arc.my_digital_moment"
                target="blank"
              >
                <Image
                  alt="Disponible en Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png"
                  height={60}
                  width={155}
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
