import Image from "next/image";
import React from "react";
import Styles from "./why-us.module.scss";
import screenshots from "../../../public/images/landing/screenshots.jpg";

function WhyUs() {
  return (
    <div className="container py-5" id="why-us-container">
      <div className="row mb-0 d-flex">
        <div className="col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 align-self-center">
          <h2 className="landing-section-title text-center">
            ¿Por qué nosotros?
          </h2>
          <ul id="why-us-list">
            <li className="mb-3">
              <em className={Styles.whyUsItemEm}>
                Te entregamos un link personalizado
              </em>
              , con una invitación rotulada para cada invitado.
            </li>
            <li className="mb-3">
              <em className={Styles.whyUsItemEm}>
                Descarga de pases en el wallet del celular
              </em>
              .
            </li>
            <li className="mb-3">
              <em className={Styles.whyUsItemEm}>App con doble función:</em>
              <br />
              Ver la confirmación de invitados en tiempo real.
              <br />
              Escanear los pases de los invitados el día del evento para que el
              host sepa quién es, cuántas personas y en qué mesa deben sentarse.
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4 d-flex">
          <div className="text-center align-self-center">
            <Image
              id="screenshots"
              src={screenshots}
              alt="screenshots"
              className={Styles.screenshots}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
