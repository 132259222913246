import Head from "next/head";
import React from "react";
import AboutUs from "../components/landing/about-us/about-us";
import Benefits from "../components/landing/benefits/benefits";
import FAQ from "../components/landing/faq/faq";
import Footer from "../components/landing/footer/footer";
import Hero from "../components/landing/hero/hero";
import WhyUs from "../components/landing/why-us/why-us";

export default function Home() {
  return (
    <>
      <Head>
        <link rel="canonical" href="https://mydigitalmoment.com" />
        <meta name="description" content="Invitaciones Digitales para boda" />
        <meta
          name="keywords"
          content="Invitaciones, boda, invitaciones digitales"
        />

        <meta property="og:title" content="My Digital Moment" />
        <meta
          property="og:description"
          content="Invitaciones digitales interactivas para boda"
        />
        <meta property="og:url" content="https://mydigitalmoment.com" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://mydigitalmoment.com/images/icons/mstile-310x310.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="760" />
        <meta property="og:image:height" content="418" />
        <meta property="og:image:alt" content="My Digital Moment" />
        <meta property="fb:app_id" content="1032660833944582" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="My Digital Moment" />
        <meta
          name="twitter:description"
          content="Invitaciones digitales interactivas para boda"
        />
        <meta name="twitter:creator" content="@aletsdelarosa" />
        <meta
          name="twitter:image"
          content="https://mydigitalmoment.com/images/icons/mstile-310x310.png"
        />
        <meta name="twitter:image:alt" content="My Digital Moment" />
        <meta name="twitter:domain" content="https://mydigitalmoment.com" />
      </Head>
      <div id="app" className="h-100 w-100 p-0 m-0">
        <main className="h-100 w-100 p-0 m-0">
          <div id="content" className="h-100 w-100 p-0 m-0">
            <Hero />
            <AboutUs />
            <Benefits />
            <WhyUs />
            <FAQ />
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
}
