import Image from "next/image";
import React from "react";
import Styles from "./benefits.module.scss";
import benefit1 from "../../../public/images/landing/benefits/1.png";
import benefit2 from "../../../public/images/landing/benefits/2.png";
import benefit3 from "../../../public/images/landing/benefits/3.png";
import benefit4 from "../../../public/images/landing/benefits/4.png";
import benefit5 from "../../../public/images/landing/benefits/5.png";
import benefit6 from "../../../public/images/landing/benefits/6.png";

function Benefits() {
  return (
    <div className="container" id="benefits-container">
      <div className="row my-3">
        <div className="col text-center mt-5 mb-5">
          <h2 className="landing-section-title m-0">
            Beneficios de una invitación digital
          </h2>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        <div className="col">
          <div className="card bg-secondary border-0 h-100">
            <div className="card-body d-flex">
              <div
                className={`${Styles.benefitsImageDiv} d-flex align-items-center`}
              >
                <Image
                  className={Styles.benefitsImage}
                  src={benefit1}
                  alt="Ahorro"
                />
              </div>
              <div
                className={`${Styles.benefitsText} d-flex align-items-center`}
              >
                <p className="card-text text-primary">
                  Representamos un ahorro en el presupuesto de tu boda.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-secondary border-0 h-100">
            <div className="card-body d-flex">
              <div
                className={`${Styles.benefitsImageDiv} d-flex align-items-center`}
              >
                <Image
                  className={Styles.benefitsImage}
                  src={benefit2}
                  alt="Paperless"
                />
              </div>
              <div
                className={`${Styles.benefitsText} d-flex align-items-center`}
              >
                <p className="card-text text-primary">
                  ¡Ecofriendly! Somos 100% paperless.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-secondary border-0 h-100">
            <div className="card-body d-flex">
              <div
                className={`${Styles.benefitsImageDiv} d-flex align-items-center`}
              >
                <Image
                  className={Styles.benefitsImage}
                  src={benefit3}
                  alt="Tiempos"
                />
              </div>
              <div
                className={`${Styles.benefitsText} d-flex align-items-center`}
              >
                <p className="card-text text-primary">
                  El control que permitimos sobre los invitados.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-secondary border-0 h-100">
            <div className="card-body d-flex">
              <div
                className={`${Styles.benefitsImageDiv} d-flex align-items-center`}
              >
                <Image
                  className={Styles.benefitsImage}
                  src={benefit4}
                  alt="Tiempos"
                />
              </div>
              <div
                className={`${Styles.benefitsText} d-flex align-items-center`}
              >
                <p className="card-text text-primary">
                  Los tiempos récord de entrega.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-secondary border-0 h-100">
            <div className="card-body d-flex">
              <div
                className={`${Styles.benefitsImageDiv} d-flex align-items-center`}
              >
                <Image
                  className={Styles.benefitsImage}
                  src={benefit5}
                  alt="Rapidez"
                />
              </div>
              <div
                className={`${Styles.benefitsText} d-flex align-items-center`}
              >
                <p className="card-text text-primary">
                  Brindamos rapidez y efectividad para el día del evento.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-secondary border-0 h-100">
            <div className="card-body d-flex">
              <div
                className={`${Styles.benefitsImageDiv} d-flex align-items-center`}
              >
                <Image
                  className={Styles.benefitsImage}
                  src={benefit6}
                  alt="Modernización"
                />
              </div>
              <div
                className={`${Styles.benefitsText} d-flex align-items-center`}
              >
                <p className="card-text text-primary">
                  La moderminzación que le otrorga a ese gran día.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
