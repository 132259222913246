import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Styles from "./faq.module.scss";

function FAQ() {
  const [open, setOpen] = useState("");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div className={`${Styles.faqContainer} container-fluid pt-5 pb-5`}>
      <div className="row">
        <div id="faq-title" className="col p-4">
          <h2 className="landing-section-title text-center mb-0">
            Preguntas Frecuentes
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="1">
                ¿Cuál es el tiempo de entrega?
              </AccordionHeader>
              <AccordionBody accordionId="1">
                7 días hábiles.
                <br />
                Los días de entrega comienzan a contar a partir de que se
                entreguen y aprueben los formularios con la información
                solicitada por el proveedor.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="2">
                ¿Cuál es la vigencia de mi página?
              </AccordionHeader>
              <AccordionBody accordionId="2">
                La página tiene vigencia de 1 año en la web, mes extra se
                cobrará de manera adicional.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="3">
                ¿Cuántos cambios puedo hacerle?
              </AccordionHeader>
              <AccordionBody accordionId="3">
                El cliente tiene derecho a dos revisiones al template de su
                elección.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="4">
                ¿Cómo se cuentan los pases?
              </AccordionHeader>
              <AccordionBody accordionId="4">
                Cada link personalizado tiene un pase. Si este pase requiere
                cambios (Modificar el número de personas, número de mesa,
                corregir o cambiar nombres), este se cuenta como otro pase.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="5">
                ¿Qué pasa si necesito pases extra?
              </AccordionHeader>
              <AccordionBody accordionId="5">
                Si necesitas pases extra, estos tienen un costo de $10.00 pesos
                por pase adicional.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="6">
                ¿Cuáles son los entregables finales?
              </AccordionHeader>
              <AccordionBody accordionId="6">
                <ul>
                  <li>Página Web.</li>
                  <li>
                    Un Excel que incluye todos los links personalizados para
                    cada invitado.
                  </li>
                  <li>
                    Acceso al App para ver confirmaciones de asistencia y
                    escanear los pases el día del evento.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="7">
                ¿Cuáles métodos de pago aceptan?
              </AccordionHeader>
              <AccordionBody accordionId="7">
                Transferencias bancarias.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
